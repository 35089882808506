<script>
import StoreSupportForm from '@app/components/StoreSupportForm.vue';
import StoreInformationForm from '@app/components/StoreInformationForm.vue';
import StoreDisableRegistrationsForm from '@app/components/StoreDisableRegistrationsForm.vue';
import ManageTagForm from '@app/components/ManageTagForm.vue.vue';

export default {
  head: {
    title: 'Général - Paramètres',
  },
  components: {
    StoreSupportForm,
    StoreInformationForm,
    StoreDisableRegistrationsForm,
    ManageTagForm,
  },
};
</script>

<template>
  <div>
    <StoreInformationForm />
    <StoreSupportForm />
    <ManageTagForm />
    <StoreDisableRegistrationsForm />
  </div>
</template>
