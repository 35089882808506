<script>
import { email } from 'vuelidate/lib/validators';
import { phone } from '@shared/config/vuelidate';
import { genericForms } from '@shared/mixins/forms';

export default {
  mixins: [genericForms],
  form: {
    reply_to_email: {
      label: 'Email de réponse (reply-to)',
      type: 'email',
      column: 'is-12',
      errors: ['email'],
      help: `
        Vos apprenants verront cette adresse quand nous leurs envoyons des e‑mails
        en votre nom et qu'ils souhaitent y répondre
      `,
      inputAttrs: {
        placeholder: 'mon@email.fr',
      },
    },
    support_email: {
      label: 'Email de votre support (SAV)',
      type: 'email',
      column: 'is-12',
      errors: ['email'],
      inputAttrs: {
        placeholder: 'mon@email.fr',
      },
    },
    support_phone: {
      label: 'Téléphone de votre support (SAV)',
      type: 'tel',
      column: 'is-12',
      errors: ['phone'],
      inputAttrs: {
        placeholder: '0100000000',
      },
    },
  },
  data() {
    return {
      isLoading: false,
      store: {
        reply_to_email: '',
        support_email: '',
        support_phone: '',
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  validations: {
    store: {
      reply_to_email: { email },
      support_email: { email },
      support_phone: { phone },
    },
  },
  created() {
    Object.keys(this.store)
      .forEach((k) => (this.store[k] = this.authStore[k]));
  },
  methods: {
    action() {
      return this.$store.dispatch('auth/updateStore', { ...this.store });
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Communication et SAV
      </h2>
      <p>
        Il est très important que vos apprenants puissent vous contacter ou trouver
        toute information nécessaire facilitant leur navigation.
      </p>
      <p>Ces informations ne seront accessibles qu'à vos apprenants.</p>
      <p>Tous les champs sont facultatifs.</p>
    </div>

    <div class="column">
      <form novalidate class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="columns is-multiline">
          <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
            <BaseField v-model="store[k]" :v="$v.store[k]" :field="field" />
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>
