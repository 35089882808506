<script>
import APIStore from '@app/services/API/Store';

export default {
  created() {
    APIStore.getTags(this.$store.getters['auth/store'].uuid)
      .then((tags) => this.existingTags = tags);
  },
  data() {
    return {
      existingTags: [],
    };
  },
  methods: {
    handleEdit(tag) {
      this.$buefy.dialog.prompt({
        message: 'La modification de l\'étiquette entraînera la modification à tous les endroits où l\'étiquette est utilisée',
        inputAttrs: { type: 'text', value: tag, required: true },
        onConfirm: (newValue) => {
          APIStore.updateTag(this.$store.getters['auth/store'].uuid, tag, newValue)
            .then(() => {
              this.existingTags = this.existingTags.map(
                (existingTag) => (existingTag === tag ? newValue : existingTag),
              );
            });
        },
      });
    },
    handleDelete(tag) {
      this.$buefy.dialog.confirm({
        title: 'Action définitive',
        message: `
            Êtes-vous sûr(e) de vouloir supprimer l'étiquette <strong>${tag}</strong> ?
            Elle n'apparaîtra plus dans les formations et les packs associés.
        `,
        type: 'is-danger',
        focusOn: 'confirm',
        canCancel: ['button'],
        cancelText: 'Annuler',
        confirmText: 'Supprimer',
        onConfirm: () => {
          APIStore.deleteTag(this.$store.getters['auth/store'].uuid, tag)
            .then(() => {
              this.existingTags = this.existingTags.filter((existingTag) => existingTag !== tag);
            });
        },
      });
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Gestion des étiquettes
      </h2>
      <p>
        Les étiquettes (ou tags) vous permettent d'organiser vos formations et vos packs,
        et restent invisibles pour vos visiteurs et apprenants.
        Lorsque vous avez beaucoup d'étiquettes, il est possible d'en supprimer ici.
      </p>
    </div>

    <div class="column">
      <div class="box content">
        <p class="has-text-success has-text-weight-bold">
          Vos étiquettes
        </p>
        <b-taglist v-if="existingTags.length">
          <span v-for="tag in existingTags" :key="tag" class="tag is-primary" @click="handleEdit(tag)"
            style="cursor: pointer;">
            {{ tag }}
            <button class="delete is-small" @click.stop="handleDelete(tag)"></button>
          </span>
        </b-taglist>
        <p v-else>Aucune étiquette</p>
      </div>
    </div>
  </div>
</template>
